.text-image-block,
.text-block {
    margin: 0 auto 48px;

    a {
        text-transform: none;
        font-size: 16px;
    }

    .link {
        margin-top: 25px;
    }

    .link--desktop {
        display: flex;

        @include mq($mobile-pt) {
            display: none;
        }
    }

    .link--mobile {
        display: none;

        @include mq($mobile-pt) {
            display: flex;
        }
    }

    &.image-first,
    &.image-last {
        .content-wrapper {
            display: flex;
            justify-content: space-between;

            @include mq($mobile-pt) {
                .text {
                    max-width: 100%;

                    &:first-child {
                        margin-bottom: 20px;
                    }
                }
            }

            .image,
            .text {
                width: 100%;
                max-width: calc(50% - 16px);

                a {
                    text-transform: none;
                    font-size: 16px;
                }

                img {
                    max-width: 100%;
                }
            }
        }
    }

    &.image-last {
        .content-wrapper {
            flex-direction: row;

            @include mq($mobile-pt) {
                flex-direction: column;

                .image,
                .text {
                    width: 100%;
                    max-width: 100%;
                }
            }
        }
    }

    &.image-first {
        .content-wrapper {
            flex-direction: row-reverse;

            @include mq($mobile-pt) {
                flex-direction: column-reverse;

                .image,
                .text {
                    width: 100%;
                    max-width: 100%;
                }
            }
        }
    }

    &.text-centered {
        text-align: center;
    }

    .main-title {
        text-transform: uppercase;
        @include h4;

        margin: 0 0 16px;

        @include mq($mobile-max) {
            @include h4;

            margin: 0 0 8px;
        }

        span {
            display: block;
        }
    }

    .text {
        @include body;

        p {
            @include body;

            margin: 0 0 20px;

            @include mq($mobile-max) {
                margin: 0 0 10px;
            }
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            @include h7;

            font-family: $heading-pro-treble-extra-bold;
            text-transform: uppercase;
            margin: 0 0 23px;

            @include mq($mobile-max) {
                margin: 0 0 20px;
            }
        }

        ul,
        ol {
            margin: 0 0 30px;

            li,
            a {
                @include body;

                text-transform: lowercase;
                line-height: 32px;
            }

            @include mq($mobile-max) {
                margin: 0 0 20px;
            }
        }

        ul {
            text-align: left;
            display: inline-block;

            li {
                margin-bottom: 10px;
                padding-left: 27px;
                position: relative;
                text-transform: none;

                &::before {
                    @include iconfont-styles;

                    content: svg(check);
                    position: absolute;
                    left: 0;
                    top: 0;
                    display: inline-block;
                    vertical-align: middle;
                    font-size: 19px;
                    color: $safety-orange;
                }
            }
        }

        ol {
            list-style: decimal;
        }

        iframe {
            @include mq($mobile-max) {
                width: 100%;
            }
        }
    }

    @include mq($mobile-max) {
        margin: 0 auto 24px;
    }
}

.text-block {
    max-width: 980px;
    padding: 50px 0;

    @include global-padding;

    @include mq($mobile-max) {
        padding: 35px 16px;
    }

    @include mq($until-dt-min) {
        max-width: 900px;
    }

    @include mq($tablet-max) {
        max-width: 892px;
    }

    .text {
        p {
            a {
                text-decoration: underline;
            }
        }
    }
}

.text-image-block {
    &--white {
        background: $white;
    }

    &--black {
        background: $black;
    }

    &--orange {
        background: $safety-orange;
    }

    &--gradient {
        background: radial-gradient(50% 50% at 50% 50%, #555 0%, $nero 100%);
    }

    .block-wrapper {
        max-width: 980px;
        padding: 50px 0;
        margin: 0 auto;

        @include global-padding;

        @include mq($mobile-max) {
            padding: 35px 16px;
        }

        @include mq($until-dt-min) {
            max-width: 900px;
        }

        @include mq($tablet-max) {
            max-width: 892px;
        }
    }

    .main-title {
        &.color-white {
            color: $white;

            .title-opposite {
                text-shadow: none;
            }
        }

        &.color-black {
            color: $black;
        }
    }

    .text {
        &.black {
            color: $black;

            // Overwrite inline styles from PD
            p,
            span {
                color: $black !important;
            }
        }

        &.white {
            color: $white;

            // Overwrite inline styles from PD
            p,
            span {
                color: $white !important;
            }
        }
    }

    @include mq($mobile-max) {
        &--white-mobile {
            background: $white;
        }

        &--black-mobile {
            background: $black;
        }

        &--orange-mobile {
            background: $safety-orange;
        }

        &--gradient-mobile {
            background: radial-gradient(50% 50% at 50% 50%, #555 0%, $nero 100%);
        }
    }
}

// Page designer component
.experience-assets-contenttextimage {
    .text-image-block {
        .text {
            ul,
            ol {
                li,
                a {
                    text-transform: none;
                }
            }
        }
    }
}
