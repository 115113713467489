/** font family **/
@media only screen and (min-width: 768px) {
  /*! "$not-mobile" */
}
@media only screen and (min-width: 901px) {
  /*! "$not-mobile-tablet" */
}
@media only screen and (min-width: 1025px) {
  /*! "$not-mobile-tablet" */
}
@media only screen and (max-width: 1599px) {
  /*! "until-dt-max" */
}
@media only screen and (max-width: 1366px) {
  /*! "until-dt-med" */
}
@media only screen and (max-width: 1279px) {
  /*! "until-dt-min" */
}
@media only screen and (min-width: 1280px) {
  /*! "dt-min" */
}
@media only screen and (max-width: 1024px) {
  /*! "tablet-max" */
}
@media only screen and (min-width: 901px) and (max-width: 1024px) {
  /*! "tablet-ls" */
}
@media only screen and (max-width: 900px) {
  /*! "mobile-tablet-pt" */
}
@media only screen and (min-width: 768px) and (max-width: 900px) {
  /*! "tablet-pt" */
}
@media only screen and (max-width: 767px) {
  /*! "$mobile-max" */
}
@media only screen and (max-width: 415px) {
  /*! "$mobile-pt" */
}
.text-image-block,
.text-block {
  margin: 0 auto 48px;
}
.text-image-block a,
.text-block a {
  text-transform: none;
  font-size: 16px;
}
.text-image-block .link,
.text-block .link {
  margin-top: 25px;
}
.text-image-block .link--desktop,
.text-block .link--desktop {
  display: flex;
}
@media only screen and (max-width: 415px) {
  .text-image-block .link--desktop,
.text-block .link--desktop {
    display: none;
  }
}
.text-image-block .link--mobile,
.text-block .link--mobile {
  display: none;
}
@media only screen and (max-width: 415px) {
  .text-image-block .link--mobile,
.text-block .link--mobile {
    display: flex;
  }
}
.text-image-block.image-first .content-wrapper, .text-image-block.image-last .content-wrapper,
.text-block.image-first .content-wrapper,
.text-block.image-last .content-wrapper {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 415px) {
  .text-image-block.image-first .content-wrapper .text, .text-image-block.image-last .content-wrapper .text,
.text-block.image-first .content-wrapper .text,
.text-block.image-last .content-wrapper .text {
    max-width: 100%;
  }
  .text-image-block.image-first .content-wrapper .text:first-child, .text-image-block.image-last .content-wrapper .text:first-child,
.text-block.image-first .content-wrapper .text:first-child,
.text-block.image-last .content-wrapper .text:first-child {
    margin-bottom: 20px;
  }
}
.text-image-block.image-first .content-wrapper .image,
.text-image-block.image-first .content-wrapper .text, .text-image-block.image-last .content-wrapper .image,
.text-image-block.image-last .content-wrapper .text,
.text-block.image-first .content-wrapper .image,
.text-block.image-first .content-wrapper .text,
.text-block.image-last .content-wrapper .image,
.text-block.image-last .content-wrapper .text {
  width: 100%;
  max-width: calc(50% - 16px);
}
.text-image-block.image-first .content-wrapper .image a,
.text-image-block.image-first .content-wrapper .text a, .text-image-block.image-last .content-wrapper .image a,
.text-image-block.image-last .content-wrapper .text a,
.text-block.image-first .content-wrapper .image a,
.text-block.image-first .content-wrapper .text a,
.text-block.image-last .content-wrapper .image a,
.text-block.image-last .content-wrapper .text a {
  text-transform: none;
  font-size: 16px;
}
.text-image-block.image-first .content-wrapper .image img,
.text-image-block.image-first .content-wrapper .text img, .text-image-block.image-last .content-wrapper .image img,
.text-image-block.image-last .content-wrapper .text img,
.text-block.image-first .content-wrapper .image img,
.text-block.image-first .content-wrapper .text img,
.text-block.image-last .content-wrapper .image img,
.text-block.image-last .content-wrapper .text img {
  max-width: 100%;
}
.text-image-block.image-last .content-wrapper,
.text-block.image-last .content-wrapper {
  flex-direction: row;
}
@media only screen and (max-width: 415px) {
  .text-image-block.image-last .content-wrapper,
.text-block.image-last .content-wrapper {
    flex-direction: column;
  }
  .text-image-block.image-last .content-wrapper .image,
.text-image-block.image-last .content-wrapper .text,
.text-block.image-last .content-wrapper .image,
.text-block.image-last .content-wrapper .text {
    width: 100%;
    max-width: 100%;
  }
}
.text-image-block.image-first .content-wrapper,
.text-block.image-first .content-wrapper {
  flex-direction: row-reverse;
}
@media only screen and (max-width: 415px) {
  .text-image-block.image-first .content-wrapper,
.text-block.image-first .content-wrapper {
    flex-direction: column-reverse;
  }
  .text-image-block.image-first .content-wrapper .image,
.text-image-block.image-first .content-wrapper .text,
.text-block.image-first .content-wrapper .image,
.text-block.image-first .content-wrapper .text {
    width: 100%;
    max-width: 100%;
  }
}
.text-image-block.text-centered,
.text-block.text-centered {
  text-align: center;
}
.text-image-block .main-title,
.text-block .main-title {
  text-transform: uppercase;
  font-size: 32px;
  line-height: 36px;
  margin: 0 0 16px;
}
@media only screen and (max-width: 767px) {
  .text-image-block .main-title,
.text-block .main-title {
    font-size: 32px;
    line-height: 36px;
    margin: 0 0 8px;
  }
}
.text-image-block .main-title span,
.text-block .main-title span {
  display: block;
}
.text-image-block .text,
.text-block .text {
  font-family: "HeadingProDouble-Regular", sans-serif;
  font-size: 16px;
  line-height: 28px;
}
.text-image-block .text p,
.text-block .text p {
  font-family: "HeadingProDouble-Regular", sans-serif;
  font-size: 16px;
  line-height: 28px;
  margin: 0 0 20px;
}
@media only screen and (max-width: 767px) {
  .text-image-block .text p,
.text-block .text p {
    margin: 0 0 10px;
  }
}
.text-image-block .text h1,
.text-image-block .text h2,
.text-image-block .text h3,
.text-image-block .text h4,
.text-image-block .text h5,
.text-image-block .text h6,
.text-block .text h1,
.text-block .text h2,
.text-block .text h3,
.text-block .text h4,
.text-block .text h5,
.text-block .text h6 {
  font-size: 20px;
  line-height: 26px;
  font-family: "HeadingProTreble-ExtraBold", sans-serif;
  text-transform: uppercase;
  margin: 0 0 23px;
}
@media only screen and (max-width: 767px) {
  .text-image-block .text h1,
.text-image-block .text h2,
.text-image-block .text h3,
.text-image-block .text h4,
.text-image-block .text h5,
.text-image-block .text h6,
.text-block .text h1,
.text-block .text h2,
.text-block .text h3,
.text-block .text h4,
.text-block .text h5,
.text-block .text h6 {
    margin: 0 0 20px;
  }
}
.text-image-block .text ul,
.text-image-block .text ol,
.text-block .text ul,
.text-block .text ol {
  margin: 0 0 30px;
}
.text-image-block .text ul li,
.text-image-block .text ul a,
.text-image-block .text ol li,
.text-image-block .text ol a,
.text-block .text ul li,
.text-block .text ul a,
.text-block .text ol li,
.text-block .text ol a {
  font-family: "HeadingProDouble-Regular", sans-serif;
  font-size: 16px;
  line-height: 28px;
  text-transform: lowercase;
  line-height: 32px;
}
@media only screen and (max-width: 767px) {
  .text-image-block .text ul,
.text-image-block .text ol,
.text-block .text ul,
.text-block .text ol {
    margin: 0 0 20px;
  }
}
.text-image-block .text ul,
.text-block .text ul {
  text-align: left;
  display: inline-block;
}
.text-image-block .text ul li,
.text-block .text ul li {
  margin-bottom: 10px;
  padding-left: 27px;
  position: relative;
  text-transform: none;
}
.text-image-block .text ul li::before,
.text-block .text ul li::before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  color: inherit;
  content: "\f107";
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  vertical-align: middle;
  font-size: 19px;
  color: #fe7000;
}
.text-image-block .text ol,
.text-block .text ol {
  list-style: decimal;
}
@media only screen and (max-width: 767px) {
  .text-image-block .text iframe,
.text-block .text iframe {
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .text-image-block,
.text-block {
    margin: 0 auto 24px;
  }
}

.text-block {
  max-width: 980px;
  padding: 50px 0;
  padding-left: 60px;
  padding-right: 60px;
}
@media only screen and (max-width: 1279px) {
  .text-block {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media only screen and (max-width: 1024px) {
  .text-block {
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .text-block {
    padding: 35px 16px;
  }
}
@media only screen and (max-width: 1279px) {
  .text-block {
    max-width: 900px;
  }
}
@media only screen and (max-width: 1024px) {
  .text-block {
    max-width: 892px;
  }
}
.text-block .text p a {
  text-decoration: underline;
}

.text-image-block--white {
  background: #fff;
}
.text-image-block--black {
  background: #313136;
}
.text-image-block--orange {
  background: #fe7000;
}
.text-image-block--gradient {
  background: radial-gradient(50% 50% at 50% 50%, #555 0%, #2d2d2d 100%);
}
.text-image-block .block-wrapper {
  max-width: 980px;
  padding: 50px 0;
  margin: 0 auto;
  padding-left: 60px;
  padding-right: 60px;
}
@media only screen and (max-width: 1279px) {
  .text-image-block .block-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media only screen and (max-width: 1024px) {
  .text-image-block .block-wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .text-image-block .block-wrapper {
    padding: 35px 16px;
  }
}
@media only screen and (max-width: 1279px) {
  .text-image-block .block-wrapper {
    max-width: 900px;
  }
}
@media only screen and (max-width: 1024px) {
  .text-image-block .block-wrapper {
    max-width: 892px;
  }
}
.text-image-block .main-title.color-white {
  color: #fff;
}
.text-image-block .main-title.color-white .title-opposite {
  text-shadow: none;
}
.text-image-block .main-title.color-black {
  color: #313136;
}
.text-image-block .text.black {
  color: #313136;
}
.text-image-block .text.black p,
.text-image-block .text.black span {
  color: #313136 !important;
}
.text-image-block .text.white {
  color: #fff;
}
.text-image-block .text.white p,
.text-image-block .text.white span {
  color: #fff !important;
}
@media only screen and (max-width: 767px) {
  .text-image-block--white-mobile {
    background: #fff;
  }
  .text-image-block--black-mobile {
    background: #313136;
  }
  .text-image-block--orange-mobile {
    background: #fe7000;
  }
  .text-image-block--gradient-mobile {
    background: radial-gradient(50% 50% at 50% 50%, #555 0%, #2d2d2d 100%);
  }
}

.experience-assets-contenttextimage .text-image-block .text ul li,
.experience-assets-contenttextimage .text-image-block .text ul a,
.experience-assets-contenttextimage .text-image-block .text ol li,
.experience-assets-contenttextimage .text-image-block .text ol a {
  text-transform: none;
}